/* Reset */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
}

/* Typography */

@font-face{
    font-family: 'sh';
    src: url('/assets/fonts/sh.otf');
    font-weight: 200;
}
@font-face{
    font-family: 'karrik';
    src: url('/assets/fonts/karrik.otf');
    font-weight: 200;
}
@font-face{
    font-family: 'karrik';
    src: url('/assets/fonts/karrik-italic.otf');
    font-weight: 200;
    font-style: italic;
}
p{font-size: 1rem;}
h1{font-size: 2rem;}
h2{font-size: 1.4rem;}
h3{font-size: 0.8rem;}

/* Typography */

a{
	text-decoration: none;
	color: inherit;
}
a:hover, .active{
	text-decoration: none;
	font-style: italic;
	cursor:pointer;
}
.sub{
	a{opacity:0.6;}
	.active{
		opacity:1;
		font-style: normal;
	}
}
h2 a{text-decoration:underline;}
h3 a{text-decoration:underline;}
.categories h3 a{text-decoration:none;}

.title{
	display:block;
	margin-bottom:10px;
	text-decoration:underline;
}

/* General */

body {
	background-color: black;
	font-family: 'karrik';
	color: red;
	font-size: 100%;
} 

@keyframes fadeInAnimation { 
	0% {opacity: 0;} 
	100% {opacity: 1;} 
} 

.mobile{display:none !important;}
.in{display: inline-block !important;}
.back{display: none;}
.em-space{padding-right: 1em;}
.half{
	width:50%;
	margin-right:5px;
}
.full{width:100%;}
.half:nth-child(2n){
	margin-left:5px;
	margin-right:0px !important;
}

/* Home animation */

#door, #herse{
	position: fixed;
	top: 0;
	width: 100%;
	height: 100vh;
	background-image: url('/assets/images/20210710_SH_LLB_SITE_ASSETS-MUR-4000.svg');
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	z-index: 100;
}
#background{
	width: 100%;
	height: 100vh;
	position: fixed;
	top: 0;
	z-index: 100;
}
#herse{
	position: relative;
	background-image: url('/assets/images/20210710_SH_LLB_SITE_ASSETS-HERSE-4000.svg');
	z-index: 10;
	background-size:auto 100%;
}
#logo-home-anim{position:fixed;}
.logo-home{
	top:calc(50% - 0.5em);
	width:100%;
	font-family:'sh';
	line-height:0.5;
	font-size:8rem;
	padding-bottom:10px;
	color:black;
	text-align:center;
	cursor:pointer;
}
.logo-top{
	margin-top:40px;
	top:0px;
	font-size:8rem;
	color:red;
}
.logo-small{font-size: 4.4rem !important;}

/* Nav */

#header{
	position:fixed;
	top:0;
	width:100%;
	background-color:black;
	display:none;
	z-index:2;
}
nav{
	position:relative;
	width:100%;
	text-align:center;
	padding-top:10px;
	z-index:2;
	background-color:black;
}
.pages{
	margin-bottom:20px;
}
.categories{
	display:flex;
	width:calc(80% - 20px);
	margin-left:calc(10% + 10px);
	border-top:1px solid;
	border-bottom:1px solid;
	padding:8px 0px 8px 0px;
	text-align:left;
	.title{margin-bottom:0px;}
}

/* Home */

#content{
	display:none;
	width:calc(80% - 20px);
	margin-left:calc(10% + 10px);
	margin-bottom:10px;
	z-index:1;
	animation: fadeInAnimation 0.8s; 
	animation-fill-mode: forwards; 
	animation-iteration-count: 1; 
}
.full-page{
	width:calc(100% - 40px) !important;
	margin-left:20px !important;
}
.project-item{
	position:relative;
	width:calc(25% - 7.5px);
	margin-right:10px;
	margin-top:10px;
	height:auto;
	float:left;
}
.project-item:nth-child(4n){margin-right: 0px !important;}
.project-item-image{
	height: 14vw;
	width: 100%;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	transition:0.15s 0.02s;
}
.project-item-text{
	position:absolute;
	width:100%;
	top:calc(50% - 0.5rem);
	text-align:center;
	opacity:0;
	transition:0.15s 0.02s;
}
.project-item:hover{
	.project-item-image{opacity:0.4;}
	.project-item-text{
		opacity:1 !important;
		font-style:normal !important;
	}
}

/* Projet */

.info-panel{
	position:fixed;
	width:calc(25% - 20px);
	margin-top:11px;
}
.info-panel-head{
	display:flex;
	flex-direction:row;
	p{flex:2;}
}
.projet-gallery{
	width:calc(50% - 20px);
	margin-left:calc(25% + 10px);
	padding-top:10px;
}
.projet-image{
	margin-bottom:40px;
	display:inline-block;
	width:100%;
}
.projet-video{
	margin-bottom:40px;
	position: relative;
  	overflow: hidden;
	padding-top: 56.25%;
	iframe{
		position: absolute;
  		top: 0;
  		left: 0;
  		bottom: 0;
  		right: 0;
  		width: 100%;
  		height: 100%;
  		figcaption{display:none;}
	}
}
.line{
	display:flex;
	margin-top:10px;
	padding-top:10px;
	width:100%;
	border-top:1px solid;
}
@media screen and (min-width: 1800px) {
	html{font-size: 150%;}
}

/* Shop */

.shop-item-image{
	display:inline-block;
	width:100%;
	transition:0.15s 0.02s;
}
.flex{
	display:flex !important;
	align-items:flex-end;
	flex-direction:row;
	flex-wrap:wrap;
}
.shop{width:calc(33.33% - 7.5px);}
.shop:nth-child(3n){margin-right: 0px !important;}
.shop:nth-child(4n){margin-right: 10px !important;}
.shop-item-link:hover{
	.shop-item-image{opacity:0.4;}
	.shop-item-title{
		opacity:1;
		font-style:normal;
	}
}
.shop-item-link{
	display:inline-block;
	position:relative;
}
.shop-item{
	width:100%;
	display:flex;
	margin-top:10px;
	border-top:1px solid;
	border-bottom:1px solid;
}
.shop-item-title{
	position:absolute;
	bottom:calc(50% - 0.5rem);
	line-height:1rem;
	width:100%;
	text-align:center;
	margin:auto;
	opacity:0;
}
.shop-item-info{
	width:50%;
	border-right:1px solid;
	h3{padding:5px 10px 5px 0px;}
}
.shop-item-prix{
	width:50%;
	padding-left:10px;
	text-align:center;
	margin:auto;
}
.sans{border:none;}
form{
	padding:0px;
	width:100%;
}
table{
	width:50%; 
	float:left;
	border-spacing:0;
	border-collapse: collapse;
}
td{
	font-size:0.8rem;
	padding-bottom:5px;
}
select{
	border:none;
	padding:0px;
	height:20px;
	font-size:0.6vw;
}
.button{
	float:left;
	margin-left:2.5%;
	width:45%;
	background-image:url('/assets/images/paypal.svg');
	background-size:contain;
	background-repeat:no-repeat;
	transition: 0.2s;
}
.button:hover{
	opacity:0.6
}
.paypal-buttons{
	opacity:0;
	height:40px!important;
}

/* About */

.info-cat{
	width:100%;
	display:flex;
	border-top:1px solid;
	padding-top:10px;
	margin-bottom:10px;
	margin-top:1px;
}

/* Media Querries */

@media screen and (max-width: 960px) {
	html{font-size: 90%;}
	.desktop{display:none !important;}
	.mobile{display:block !important;}
	.categories{display:none;}
	.pages{margin-bottom:10px;}
	.logo-home{line-height:0.6;}
	.logo-top{
		line-height:4.8rem;
	}
	.logo-small{
		font-size:4rem !important;
		line-height:2rem !important;
	}
	#content{
		display:none;
		width:calc(100% - 20px);
		margin-left:10px;
		z-index:1;
	}
	.full-page{width:calc(100% - 20px) !important;}
	.project-item{
		width:calc(50% - 5px);
		margin-right:10px;
	}
	.project-item:nth-child(2n){margin-right: 0px !important;}
	.project-item-image{height: 33vw;}
	.info-panel{
		position:relative;
		width:calc(100% - 20px);
		margin-top:20px;
		padding-top:10px;
		border-top:1px solid;
		form{margin-top:5px;}
	}
	.projet-gallery{
		width:calc(100% - 20px);
		margin-left:0px;
		padding-top:20px;
	}
	.projet-image{
		margin-bottom:20px;
	}
	.line{
		.half:nth-child(2n){margin-top:0px;}
	}
	table{
		width:50%; 
		float:left;
		border-spacing:0;
		border-collapse: collapse;
		overflow:hidden;
	}
	td{
		font-size:0.8rem;
		padding-bottom:5px;
	}
	select{
		height:10px;
		font-size:1.6vw;
	}
	.button{
		margin-left:0%;
		width:40%;
	}
	.shop{width:calc(50% - 5px);}
	.shop:nth-child(2n){margin-right: 0px !important;}
	.shop:nth-child(3n){margin-right: auto !important;}
	.shop-item-link:hover{
		.shop-item-image{opacity:0.4;}
		.shop-item-title{opacity:0;}
	}
	.shop-item-link{
		display:inline-block;
		position:relative;
	}
	.shop-item{
		width:100%;
		display:flex;
		padding-top:5px;
		border-top:1px solid;
		border-bottom:0px solid;
	}
	.shop-item-info{
		width:60%;
		border-right:0px solid;
		margin-top:0px;
		h3{padding:0px 0px 0px 0px;}
	}
	.shop-item-prix{
		width:40%;
		padding-left:0px;
		margin:0px;
		text-align:right;
		padding-top:0px;
		h1{font-size:0.8rem}
	}
	.info-cat{
		width:100%;
		display:block;
		border-top:1px solid;
		padding-top:10px;
		margin-bottom:10px;
	}
	.half{
		width:100%;
		margin-right:10px;
	}
	.half:nth-child(2n){
		margin-left:0px;
		margin-top:1em;
		margin-right:0px !important;
	}
}
@media screen and (min-width: 1800px) {
	html{font-size: 150%;}
}